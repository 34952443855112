.ew-register-user {
    .ew-bg {
        background: linear-gradient(180deg, #BB82FD 0%, #2C6BBF 100%);
    }
    .ew-border-gradient {
        border-color: #BB82FD !important;
    }
    .text-purple {
        color: #BB82FD;
        font-weight: 700;
    }

    .rbt-input {
        border: none !important;
    }
}
